<template>
  <div
    class="opportunity-card"
  >
    <b-card-group
      class="card"
    >
      <b-card-header>
        <div
          class="opportunity-status-label"
          :class="{'published': opportunity.enabled, 'draft': !opportunity.enabled}"
        >
          <span> {{ $t(`brokerDealer.opportunitiesPage.${opportunity.enabled ? 'published' : 'draft'}`) }}</span>
        </div>
        <img
          class="card-header__img"
          alt="Opportunity"
          :src="opportunity.image"
          @error="(e) => replaceByDefaultImage(e.target)"
        >
        <b-dropdown
          class="opportunity-card__options"
          right
          no-caret
          size="sm"
          variant="default icon-btn btn-round borderless"
        >
          <template slot="button-content">
            <i class="ion ion-ios-more" />
          </template>
          <b-dropdown-item @click="edit(opportunity.id)">
            <i class="fa fa-pencil-alt w-15" />
            <span class="ml-3">{{ $t('common.button.edit') }}</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item @click="openDeleteConfirmationModal(opportunity.id)">
            <i class="ion ion-ios-trash w-15" />
            <span class="ml-3">{{ $t('common.button.delete') }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
      <b-card-body>
        <div class="mb-3">
          <div class="d-flex">
            <img
              alt="Issuer logo"
              :src="opportunity.issuerLogo"
              width="20px"
              height="20px"
            >
            <span class="card-body__tag ml-2">{{ opportunity.issuerName }}</span>
          </div>
          <h1 class="card-body__title">
            {{ opportunity.title }}
          </h1>
        </div>
        <div class="card-body__container d-flex mb-3">
          <div class="card-body__container__item">
            <label class="card-body__container__item__label">{{ $t('brokerDealer.opportunitiesPage.shareValue') }}</label>
            <p class="card-body__container__item__number-value">
              $
              {{ opportunity.shareValue }}
            </p>
          </div>
        </div>
        <button
          id="view-opportunity-details"
          class="btn btn-primary btn-block view-details-button"
          block
          @click="() => edit(opportunity.id)"
        >
          {{ $t('brokerDealer.opportunitiesPage.buttons.viewDetails') }}
        </button>
      </b-card-body>
    </b-card-group>
    <cp-confirm-modal
      ref="cpConfirmModal"
      ok-variant="danger"
      :ok-text="$t('brokerDealer.opportunitiesPage.deleteConfirmModal.ok')"
      :title="$t('brokerDealer.opportunitiesPage.deleteConfirmModal.title')"
      @onOk="deleteOpportunity"
    >
      {{ $t('brokerDealer.opportunitiesPage.deleteConfirmModal.description') }}
    </cp-confirm-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import routeNames from '@/utilities/routes';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import DefaultImage from '~/assets/img/no-img.svg';

export default {
  name: 'OpportunityCard',
  components: {
    CpConfirmModal,
  },
  props: {
    opportunity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { opportunityIdToDelete: null };
  },
  methods: {
    ...mapActions('brokerDealerOpportunities', ['deleteBrokerDealerOpportunity']),
    edit(opportunityId) {
      this.$router.push(routeNames.brokerDealerEditOpportunity({ opportunityId }));
    },
    deleteOpportunity() {
      this.deleteBrokerDealerOpportunity(this.opportunityIdToDelete).then(() => {
        this.$emit('opportunityDeleted');
      });
    },
    openDeleteConfirmationModal(opportunityId) {
      this.opportunityIdToDelete = opportunityId;
      this.$refs.cpConfirmModal.show();
    },
    replaceByDefaultImage(target) {
      target.src = DefaultImage;
      target.classList.remove('card-header__img');
      target.classList.add('card-header__error-img');
    },
  },
};
</script>

<style scoped lang="scss">
  .opportunity-card {
    width: calc(100% / 3);
    padding: 0 1em 2em;
    cursor: move;

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      .card-header {
        display: flex;
        padding: 0;
        width: 100%;
        height: 180px;
        align-items: center;
        justify-content: center;
        background-color: #f4f3f3;

        &__error-img {
          height: 50%;
          width: 50%;
        }

        &__img {
          height: 100%;
          width: 100%;
        }

        .opportunity-status-label {
          width: 90px;
          height: 25px;
          border-radius: 2px;
          position: absolute;
          top: 10px;
          left: 10px;
          text-align: center;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1em;
        }
        .draft {
          background-color: #93989f;
        }

        .published {
          background-color: #00bc77;
        }
      }

      .card-body {
        width: 100%;
        padding: 1.5em 2em 2em;
        display: flex;
        flex-direction: column;

        &__tag {
          font-size: 16px;
          display: block;
          margin-bottom: 0.5em;
          font-weight: 500;
          color: #848b93;
        }
        &__title {
          font-size: 28px;
          font-weight: 500;
          color: #0a1828;
          margin: 0.5em 0 1em 0;
        }

        &__container {

          &__item {
            margin: 0 2em 1em 0;
            &__label {
              font-size: 16px;
              font-weight: 300;
              color: #0a1828;
              margin-bottom: 0;
            }

            &__text-value, &__number-value {
              font-size: 21px;
              font-weight: 500;
              color: #0a1828;
              line-height: 35px;
              margin-bottom: 0;
              text-transform: capitalize;
            }
            &__number-value {
              font-size: 28px;
            }
          }
        }
      }
    }

    .view-details-button {
      width: 75%;
      align-self: center;
    }

    &__options {
      position: absolute;
      top: 1em;
      right: 1em;
      background: white;
      border-radius: 20px;

      .w-15 {
        width: 15px;
      }
    }
  }

</style>
