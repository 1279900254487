<template>
  <cp-general-modal
    ref="createModal"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="modal-create-opportunity">
      <h4 class="mb-3">
        {{ $t('brokerDealer.opportunitiesPage.modal.title') }}
      </h4>
      <template v-if="!isLoading">
        <cp-input
          v-model="model.title"
          class="modal-create-opportunity__input mt-3"
          name="Broker Dealer Opportunity name"
          label="Broker Dealer Opportunity name"
        />
        <cp-select
          v-model="model.issuerId"
          class="modal-create-opportunity__input"
          input-class="mb-0"
          label="Issuer name"
          name="issuerName"
          :options="issuersOptions"
          :value="model.issuerId"
          @input="onIssuerChange"
        />

        <cp-select
          v-model="model.tokenId"
          class="modal-create-opportunity__input"
          input-class="mb-0"
          label="Token"
          name="token"
          :options="tokenOptions"
          :disabled="isLoadingTokens || !model.issuerId"
          :error="!isLoadingTokens && model.issuerId && tokenOptions.length === 1
            ? 'The issuer doesn\'t have any tokens' : ''"
          @input="onTokenChange"
        />

        <cp-select
          v-model="model.opportunityId"
          class="modal-create-opportunity__input"
          input-class="mb-0"
          label="Opportunity"
          name="opportunity"
          :disabled="isLoadingOpportunities || !model.tokenId"
          :error="!isLoadingOpportunities && model.tokenId && opportunitiesOptions.length === 1 ?
            'The token doesn\'t have any opportunities' : ''"
          :options="opportunitiesOptions"
        />
      </template>
      <b-spinner
        v-if="isLoading"
        class="align-middle align-self-center my-3"
        big
      />
      <div class="d-flex flex-column w-100 align-items-center">
        <b-button
          class="btn btn-primary modal-create-opportunity__button mt-3"
          :disabled="isLoading || !model.title || !model.issuerId ||!model.opportunityId"
          @click="createOpportunity"
        >
          {{ $t('brokerDealer.opportunitiesPage.modal.buttons.createOpportunity') }}
        </b-button>

        <b-button
          class="btn btn-link modal-create-opportunity__button-cancel mt-3"
          :disabled="isLoading"
          @click="close"
        >
          {{ $t('brokerDealer.opportunitiesPage.modal.buttons.cancel') }}
        </b-button>
      </div>
    </div>
  </cp-general-modal>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CpGeneralModal from '@/components/common/modals-components/general-modal';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import CpSelect from '@/components/common/standalone-components/inputs/cp-select';

export default {
  name: 'ModalCreateOpportunity',
  components: {
    CpGeneralModal,
    CpInput,
    CpSelect,
  },
  data() {
    return {
      model: { issuerId: '', tokenId: '', opportunityId: '' },
      opportunities: [],
      tokens: [],
      isLoading: false,
      isLoadingTokens: false,
      isLoadingOpportunities: false,
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    ...mapGetters('issuersInfo', ['issuerTokens']),
    issuersOptions() {
      return [{ text: 'E.g. SPiCE VC', value: '', disabled: true }]
        .concat((this.allSystemIssuersList.length ? this.allSystemIssuersList : []).map(issuer => ({ text: issuer.name, value: issuer.issuerId, disabled: false })));
    },
    tokenOptions() {
      return [{ text: 'E.g. Token A', value: '', disabled: true }]
        .concat(this.tokens.map(token => ({ text: token.name, value: token.id, disabled: false })));
    },
    opportunitiesOptions() {
      return [{ text: 'E.g. Fund A', value: '', disabled: true }]
        .concat(this.opportunities.map(opp => ({ text: opp.title, value: opp.id, disabled: false })));
    },
  },
  methods: {
    ...mapActions('brokerDealerOpportunities', ['createBrokerDealerOpportunity', 'getIssuerTokens', 'getIssuerOriginalOpportunities']),
    show() {
      this.$refs.createModal.show();
    },
    close() {
      this.$refs.createModal.hide();
      this.model = { issuerId: '', tokenId: '', opportunityId: '' };
      this.opportunities = [];
      this.tokens = [];
    },
    createOpportunity() {
      const { issuerId, opportunityId, title } = this.model;
      this.isLoading = true;
      this.createBrokerDealerOpportunity({ issuerId, opportunityId, title, order: 0 })
        .then(() => {
          this.$emit('opportunityCreated');
          this.close();
        })
        .finally(() => { this.isLoading = false; });
    },
    onIssuerChange() {
      this.model.tokenId = '';
      this.isLoadingTokens = true;
      this.getIssuerTokens(this.model.issuerId)
        .then((data) => {
          this.tokens = data;
        })
        .finally(() => { this.isLoadingTokens = false; });
    },
    onTokenChange() {
      this.model.opportunityId = '';
      this.isLoadingOpportunities = true;
      const { issuerId, tokenId } = this.model;
      if (tokenId) {
        this.getIssuerOriginalOpportunities({ issuerId, tokenId })
          .then((data) => { this.opportunities = data; })
          .finally(() => { this.isLoadingOpportunities = false; });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .modal-create-opportunity {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    min-height: 550px;

    h4 {
      width: 55%;
      text-align: center;
    }

    &__input {
      width: 80%;
      margin-bottom: 1em;
      font-size: 16px;
      font-weight: 500;
    }

    &__button, &__button-cance {
      width: 65%;
      height: 50px;
    }
    &__button-cancel, &__button-cancel:disabled {
      background: transparent !important;
      color: #55bbc2 !important;
    }

  }
</style>
