<template>
  <div class="selected-filter">
    <div>
      <span class="selected-filter__text">
        <slot />
      </span>
      <i
        class="ion ion-ios-close"
        @click="$emit('removeFilter', { type, value })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectedFilter',
  props: {
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
  .selected-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    height: 30px;
    border-radius: 20px;
    background-color: rgba(97, 125, 141, 0.1);
    margin: 1em 1em 0 0;
    padding: 1em;

    &__text {
      font-size: 14px;
      font-weight: 500;
      color: #697e8d;
    }

    i {
      font-size: 20px;
      margin-left: 0.5em;
    }

    i:hover {
      cursor: pointer;
    }
  }
</style>
