<template>
  <div class="opportunities-filters">
    <div class="d-flex justify-content-between">
      <div class="opportunities-filters__filters-container">
        <multiselect
          v-model="model.issuerIds"
          :multiple="true"
          class="opportunities-filters__multiselect"
          :options="issuersOptions"
          label="text"
          track-by="value"
          :show-labels="false"
          :placeholder="$t('brokerDealer.opportunitiesPage.filters.issuerPlaceholder')"
          @input="search"
        >
          <template
            slot="selection"
            slot-scope="{ values, isOpen }"
          >
            <div class="selected-options">
              <span
                v-if="values.length && !isOpen"
                class="multiselect__single"
              >
                {{ $t('brokerDealer.opportunitiesPage.filters.issuerPlaceholder') }}
              </span>
              <div
                v-if="values.length && !isOpen"
                class="number"
              >
                <span>
                  {{ values.length }}
                </span>
              </div>
            </div>
          </template>
        </multiselect>
        <!--<multiselect
          v-model="model.type"
          :multiple="true"
          class="opportunities-filters__multiselect"
          :options="getTranslatedOptions"
          label="text"
          track-by="value"
          :show-labels="false"
          :placeholder="$t('brokerDealer.opportunitiesPage.filters.typeOfAssetPlaceholder')"
          @input="search"
        >
          <template
            slot="selection"
            slot-scope="{ values, isOpen }"
          >
            <div class="selected-options">
              <span
                v-if="values.length && !isOpen"
                class="multiselect__single"
              >
                {{ $t('brokerDealer.opportunitiesPage.filters.typeOfAssetPlaceholder') }}
              </span>
              <div
                v-if="values.length && !isOpen"
                class="number"
              >
                {{ values.length }}
              </div>
            </div>
          </template>
        </multiselect>-->
        <cp-input
          v-model="model.search"
          :placeholder="$t('brokerDealer.opportunitiesPage.filters.searchPlaceholder')"
          @input="search"
        />
      </div>
      <b-btn
        variant="link"
        class="ion ion-ios-refresh text-muted p-0 clear-hover"
        @click="clearFilters"
      >
        {{ $t('component.cpFilter.button.filter.clear') }}
      </b-btn>
    </div>
    <div class="opportunities-filters__selected">
      <selected-filter
        v-for="option in model.issuerIds"
        :key="option.value"
        type="issuerIds"
        :value="option.value"
        @removeFilter="removeFilter"
      >
        {{ option.text }}
      </selected-filter>
      <selected-filter
        v-for="option in model.type"
        :key="option.value"
        type="type"
        :value="option.value"
        @removeFilter="removeFilter"
      >
        {{ option.text }}
      </selected-filter>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import multiselect from 'vue-multiselect';
import CpInput from '@/components/common/standalone-components/inputs/cp-input';
import { typeOfAssetOptions } from '@/pages/broker-dealer/opportunities/options';
import SelectedFilter from '@/pages/broker-dealer/opportunities/components/SelectedFilter';

export default {
  name: 'Filters',
  components: {
    SelectedFilter,
    CpInput,
    multiselect,
  },
  data() {
    return {
      model: {
        search: null,
        issuerIds: null,
        type: null,
      },
      typeOfAssetOptions,
    };
  },
  computed: {
    ...mapState('issuerList', ['allSystemIssuersList']),
    issuersOptions() {
      return (this.allSystemIssuersList.length ? this.allSystemIssuersList : []).map(issuer => ({
        text: issuer.name,
        value: issuer.issuerId,
        disabled: false,
      }));
    },
    getTranslatedOptions() {
      const options = [...this.typeOfAssetOptions];
      options.shift();
      return options.map(option => ({ text: this.$t(option.i18nKey), value: option.value }));
    },
  },
  methods: {
    search() {
      const { search, issuerIds, type } = this.model;
      this.$emit('search', {
        search,
        issuerIds: issuerIds && issuerIds.map(option => option.value),
        type: type && type.map(option => option.value),
      });
    },
    removeFilter({ type, value }) {
      const index = this.model[type].findIndex(item => item.value === value);
      this.model[type].splice(index, 1);
      this.search();
    },
    clearFilters() {
      this.model = {
        search: null,
        issuerIds: null,
        type: null,
      };
      this.search();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>
<style lang="scss">
  .opportunities-filters {
    display: flex;
    flex-direction: column;

    &__filters-container {
      display: flex;
      width: 90%;

      .input-group {
        input {
          height: 42px !important;
        }
      }
    }

    &__multiselect {
      max-width: 20%;
      margin-right: 1em;

      .selected-options {
        display: flex;
        align-items: center;

        .multiselect__single {
          padding-left: 5px;
          margin-bottom: 0;
          display: flex;
          width: auto;
          margin-right: 0.5em;
        }

        .number {
          display: flex;
          background-color: #617d8d;
          padding: 0.2em 0.4em;
          color: #f8fafb;
        }
      }
    }

    &__selected {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>
