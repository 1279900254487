<template>
  <div class="d-flex w-100 align-items-center flex-column p-5">
    <img
      class="mb-5"
      alt="No opportunities"
      :src="getImagePath('illustration.png')"
    >
    <h4 class="mb-3">
      You don't have any opportunities yet
    </h4>
    <button
      type="button"
      class="btn btn-primary btn-new-opportunity mt-3"
      @click="createOpportunity"
    >
      {{ $t('brokerDealer.opportunitiesPage.buttons.createNewOpportunity') }}
    </button>
  </div>
</template>

<script>
import staticPathGenerationMixin from '@/mixins/static-path-generation';

export default {
  name: 'NoResults',
  mixins: [staticPathGenerationMixin],
  props: {
    createOpportunity: { type: Function, required: true },
  },
};
</script>

<style scoped>
  .btn-new-opportunity {
    height: 52px;
    width: 30%;
    font-size: 16px;
    font-weight: 500;
  }
</style>
